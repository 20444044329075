function About() {
  return (
    <div className="flex place-content-center p-0 flex-col min-h-screen"  id="about">
      <div>
      <h1 className="font-bold tracking-wider md:text-2xl text-teal-500 border-b-2 border-teal-300 text-xl md:w-full dark:text-teal-300">
          About Me
        </h1>
      </div>
      <div className="mt-8 font-semibold text-lg tracking-wider max-w-xl text-teal-400 dark:text-teal-100">
        <div>
          Hi there, my name is Abdus Salam. I started to code when I was in
          Intermediate 1st year. I always had interest in Computers. I used to
          work on computers and lot of gaming too. My interest in Computer got
          me in the field of Computer Science. Later I developed many skills in
          various technologies.
        </div>
        <div className="mt-4">
          Fast-forward to today, I completed my B.Tech. in computer science and
          got to know lots of new innovations and ideas I can develop through
          it. My focus these days is to make a good career through my passion
          and ideas.
        </div>
        <div className="mt-4">
          Here are a few technologies I have been working on recently:
        </div>
        <ul className="list-disc mt-4 grid grid-cols-2 gap-x-10 gap-y-4 mx-6">
          <li>TypeScript</li>
          <li>React</li>
          <li>Node.js</li>
          <li>Next.js</li>
          <li className="col-span-2">Android Development using Java</li>
        </ul>
      </div>
    </div>
  );
}

export default About;
