import { EmojiEmotions as DefaultIcon } from "@styled-icons/material-outlined/EmojiEmotions";
import { Instagram } from "@styled-icons/bootstrap/Instagram";
import { Github } from "@styled-icons/bootstrap/Github";
import { Linkedin } from "@styled-icons/fa-brands/Linkedin";
import { Facebook } from "@styled-icons/fa-brands/Facebook";
import { TwitterOutline as Twitter } from "@styled-icons/evaicons-outline/TwitterOutline";

type iconProps = {
  iconName: string;
  size?: string;
};

export default function SetIcon({ iconName, size = "24" }: iconProps) {
  switch (iconName.toLowerCase()) {
    case "instagram":
      return <Instagram size={size} className="hover:text-fuchsia-700 rounded-md dark:hover:bg-white/70" />;
    case "github":
      return <Github size={size} className="hover:text-black dark:hover:text-white rounded-full dark:hover:bg-black" />;
    case "linkedin":
      return <Linkedin size={size} className="hover:text-linkedin dark:hover:bg-white/80 rounded-sm" />;
    case "facebook":
      return <Facebook size={size} className="hover:text-blue-700 dark:hover:bg-white/70 rounded" />;
    case "twitter":
      return <Twitter size={size} className="hover:text-blue-400 dark:hover:bg-white/70 rounded" />;
    default:
      return <DefaultIcon size={size} className="hover:text-black dark:hover:bg-white/70 rounded" />;
  }
}
