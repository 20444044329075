type btnProps = {
  name: string;
  url: string;
  textSize?: string;
};
export default function Button({
  name,
  url,
  textSize = "text-base",
}: btnProps) {
  return (
    <div>
      <button className="active:translate-y-0.1">
        <a
          href={url}
          className={`relative px-5 py-2 ${textSize} font-medium text-white group`}
        >
          <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-teal-500 group-hover:bg-teal-700 group-hover:skew-x-12" />
          <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-teal-700 group-hover:bg-teal-500 group-hover:-skew-x-12" />
          <span className="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-teal-600 -rotate-12" />
          <span className="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-teal-400 -rotate-12" />
          <span className="relative">{name}</span>
        </a>
      </button>
    </div>
  );
}
