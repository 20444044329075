import React from "react";
import socialLink from "../@types/socialLink";
import SetIcon from "./controls/setIcon";

type sLinkProps = {
  socialLinks: socialLink[];
};

export default function SocialLink({ socialLinks }: sLinkProps) {
  return (
    <>
      {socialLinks?.map((item, index) => (
        <a
          key={index}
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          className="text-teal-400 bg-transparent rounded-full p-2 hover:shadow-xl shadow-teal-500/80 hover:-translate-y-1 hover:transform duration-300 ease-in-out"
        >
          <SetIcon iconName={item.name} />
        </a>
      ))}
      <span className="h-24 w-0 border-2 border-teal-600 rounded-t-lg ml-4.5 mt-2 hidden md:flex" />
    </>
  );
}
