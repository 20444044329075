import React from "react";
import Button from "../Components/UI/button";

export default function Contact() {
  return (
    <div
      className="flex place-content-center items-center p-0 flex-col min-h-screen"
      id="contact"
    >
      <div>
        <h1 className="font-bold tracking-wider md:text-3xl text-teal-500 dark:text-teal-400">
          What's Next?
        </h1>
      </div>
      <div className="mt-4 tracking-wider max-w-xl text-teal-400 dark:text-teal-300">
        <div className="mt-4 text-6xl font-bold text-center">Get in Touch</div>
        <div className="mt-4 text-center text-teal-500 dark:text-teal-200">
          I'm looking forward to work with you, my inbox is always open. Whether
          you have questions or want to say hi, I'll try my best to get back to
          you!
        </div>
        <div className="mt-16 text-center">
            <Button name={"Say Hello"} url={"mailto:sajad.laskar1@gmail.com"} textSize={"text-xl"} />
        </div>
      </div>
    </div>
  );
}
