import {
  skillDatabase,
  skillLanguages,
  skillOS,
  skillTechnologies,
} from "../@types/constants";

function Skills() {
  return (
    <div
      className="flex place-content-center p-0 flex-col min-h-screen"
      id="skills"
    >
      <div>
        <h1 className="font-bold tracking-wider md:text-2xl text-teal-500 border-b-2 border-teal-300 text-xl md:w-full dark:text-teal-300">
          Technologies and languages I learned
        </h1>
      </div>
      <div className="mt-8 font-semibold text-md tracking-wider grid lg:grid-flow-col grid-flow-row ml-8 lg:space-x-20 dark:text-white">
        <div className="mb-8">
          <h2 className="text-md font-semibold tracking-wider mb-4">
            Languages
          </h2>
          <ul className="space-y-5 lg:space-y-4 border-l border-teal-100">
            {skillLanguages.map((item) => (
              <li
                key={item.name}
                className="block border-l pl-4 -ml-px border-transparent hover:border-teal-400 text-teal-400 hover:text-teal-500 dark:text-teal-100 dark:hover:text-teal-300"
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="mb-8">
          <h2 className="text-md font-semibold tracking-wider mb-4">
            Database Management
          </h2>
          <ul className="space-y-5 lg:space-y-4 border-l border-teal-100">
            {skillDatabase.map((item) => (
              <li
                key={item.name}
                className="block border-l pl-4 -ml-px border-transparent hover:border-teal-400 text-teal-400 hover:text-teal-500 dark:text-teal-100 dark:hover:text-teal-300"
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="mb-8">
          <h2 className="text-md font-semibold tracking-wider mb-4">
            Technologies
          </h2>
          <ul className="space-y-5 lg:space-y-4 border-l border-teal-100">
            {skillTechnologies.map((item) => (
              <li
                key={item.name}
                className="block border-l pl-4 -ml-px border-transparent hover:border-teal-400 text-teal-400 hover:text-teal-500 dark:text-teal-100 dark:hover:text-teal-300"
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="mb-8">
          <h2 className="text-md font-semibold tracking-wider mb-4">
            Operating Systems Knowledge
          </h2>
          <ul className="space-y-5 lg:space-y-4 border-l border-teal-100">
            {skillOS.map((item) => (
              <li
                key={item.name}
                className="block border-l pl-4 -ml-px border-transparent hover:border-teal-400 text-teal-400 hover:text-teal-500 dark:text-teal-100 dark:hover:text-teal-300"
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Skills;
