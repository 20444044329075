import { email, navLinks, socialLinks, title } from "../@types/constants";
import About from "../pages/about";
import Contact from "../pages/contact";
import Hero from "../pages/home";
import Skills from "../pages/skills";
import Footer from "./footer";
import NavBar from "./navBar";

/*
TODO: Dark theme, Project section,
*/

export default function Index() {
  return (
    <div className="bg-fixed bg-gradient-to-t from-teal-200 to-teal-50 dark:from-teal-900 dark:to-teal-500">
      <NavBar title={title} navLinks={navLinks} />
      <main className="flex flex-col space-y-12 px-10 pr-11 md:container md:mx-auto lg:px-20">
        <Hero />
        <About />
        <Skills />
        <Contact />
      </main>
      <Footer email={email} socialLinks={socialLinks} />
    </div>
  );
}
