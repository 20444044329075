import { Menu } from "@styled-icons/material/Menu";
import { MenuOpen } from "@styled-icons/material/MenuOpen";

type menuProps = {
  open: boolean;
};
export default function MenuControl({ open }: menuProps) {
  if (open) {
    return <MenuOpen size="26" />;
  }
  return <Menu size="26" />;
}
