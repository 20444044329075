import Button from "../Components/UI/button";

function Hero() {
  return (
    <div className="flex place-content-center p-0 flex-col min-h-screen" id="home">
      <h1 className="font-semibold flex tracking-wider text-lg ml-1 dark:text-white">
        Hi, my name is
      </h1>
      <div className="mt-4 text-5xl text-teal-500 font-bold md:text-8xl drop-shadow-xl dark:text-teal-400">
        Abdus Salam <br />
        <span className="text-teal-400 dark:text-teal-300">Laskar</span>
      </div>

      <div className="ml-1 mt-8 font-semibold text-lg tracking-wider max-w-xl text-teal-400 dark:text-teal-100">
        <div>
          I am software engineer specialized in frontend and backend development
          for scalable web apps &#40;also develop Android apps&#41;.
        </div>
        <div className="mt-2">
          Currently, I am learning and improving my skills to higher levels. I
          am open to work for you.
        </div>
      </div>

      <div className="mt-8 ml-3">
      <Button name={"Contact Me"} url={"mailto:sajad.laskar1@gmail.com"} />
      </div>
    </div>
  );
}

export default Hero;
