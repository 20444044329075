import React from "react";
import { socialLinks, version } from "../@types/constants";
import navLink from "../@types/navLink";
import SocialLink from "./socialLink";
import Button from "./UI/button";
import MenuControl from "./UI/menuControl";
import DarkmodeToggle from "react-dark-mode-toggle";

type navProps = {
  title: string;
  navLinks: navLink[];
};

export default function NavBar({ title, navLinks }: navProps) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [darkMode, setDarkMode] = React.useState(false);

  React.useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      document.documentElement.classList.remove("light");
      localStorage.setItem("darkMode", "true");
    } else {
      document.documentElement.classList.add("light");
      document.documentElement.classList.remove("dark");
      localStorage.setItem("darkMode", "false");
    }
  }, [darkMode]);
  const handleTheme = () => {
    setDarkMode(!darkMode);
    console.log(darkMode);
  };

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 backdrop-blur-sm bg-teal-200/30 rounded-b-xl px-2 py-2.5 shadow-lg mb-4 z-50">
        <div className="flex flex-wrap justify-between items-center md:mx-12">
          <a
            href="#home"
            className="relative px-5 py-1 text-3xl font-bold whitespace-nowrap tracking-wider text-teal-500 hover:text-teal-600 md:hover:scale-110 transform duration-300 ease-out group dark:text-teal-50 dark:hover:text-teal-100"
          >
            <span className="relative">{title}</span>
          </a>
          <button
            type="button"
            className="md:hidden inline-flex items-center p-2 mr-3 text-base bg-teal-200 rounded-full hover:bg-teal-300 active:ring-2"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <MenuControl open={navbarOpen} />
          </button>
          <div
            className="hidden w-full md:block md:w-auto transform duration-300 ease-in-out"
            id="mobile-menu"
          >
            <div>
              <ul className="flex flex-col items-center space-y-4 font-medium md:flex-row md:space-x-8 md:space-y-0 md:mt-0 md:text-md dark:text-teal-50">
                {navLinks.map((item) => (
                  <li key={item.id}>
                    <a
                      href={item.url}
                      className="relative text-base tracking-wider group md:bg-transparent md:p-0 active:translate-y-0.5"
                      aria-current="page"
                    >
                      {item.name}
                      <span className="hidden absolute -bottom-4 left-1/4 w-0 h-1 bg-teal-600 rounded-r group-hover:w-11/12 group-hover:transform duration-300 ease-in-out md:block dark:bg-teal-200"></span>
                      <span className="hidden absolute -bottom-4 right-1/4 w-0 h-1 bg-teal-600 rounded-l group-hover:w-11/12 group-hover:transform duration-300 ease-in-out md:block dark:bg-teal-200"></span>
                    </a>
                  </li>
                ))}
                <Button
                  name="Resume"
                  url={require("../assets/Abdus_Salam_Laskar_Resume.pdf")}
                />
                <DarkmodeToggle
                  checked={darkMode}
                  onChange={handleTheme}
                  size="60"
                />
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* Side Panel */}
      <div
        className={`${navbarOpen ? "w-1/2" : "w-0"} +
        fixed overflow-y-hidden flex flex-col justify-between md:hidden h-full backdrop-blur-xl bg-teal-300/10 shadow-lg top-16 right-0 z-10 transform duration-300 ease-in-out`}
      >
        <div className="flex flex-col items-center space-y-8 font-medium inset-0 mt-10 dark:text-teal-50">
          {navLinks.map((item) => (
            <a
              key={item.name}
              href={item.url}
              className="text-base tracking-wider group md:bg-transparent md:p-0 active:translate-y-0.5"
              aria-current="page"
            >
              {item.name}
            </a>
          ))}
          <Button
            name="Resume"
            url={require("../assets/Abdus_Salam_Laskar_Resume.pdf")}
          />
        </div>
        <div className="text-center">
          <div>
            <DarkmodeToggle
              checked={darkMode}
              onChange={handleTheme}
              size="60"
            />
          </div>
          <div>
            <SocialLink socialLinks={socialLinks} />
          </div>
          <div className="text-gray-600 mb-20 mt-2 dark:text-gray-300">
            {version}
          </div>
        </div>
      </div>
    </>
  );
}
