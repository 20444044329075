import { version } from "../@types/constants";
import socialLink from "../@types/socialLink";
import SocialLink from "./socialLink";

type footerProps = {
  email: string;
  socialLinks: socialLink[];
};

export default function Footer({ email, socialLinks }: footerProps) {
  return (
    <>
      <div className="fixed bottom-64 -right-16 rotate-90 font-semibold text-teal-500 md:-right-8 hover:-translate-y-1 hover:transform duration-300 ease-in-out z-20 dark:text-teal-300">
        <a href={`mailto:${email}`}>{email}</a>
      </div>
      <span className="fixed bottom-0 right-7 h-40 w-0 border-2 border-teal-600 rounded-t-lg ml-4 mt-2 md:right-15 z-20 dark:border-teal-500" />
      <div className="hidden bottom-0 left-2 flex-col space-y-1 lg:left-10 fixed z-20 md:flex">
        <SocialLink socialLinks={socialLinks} />
      </div>
      <div className="hidden bg-teal-50/50 md:flex flex-col font-semibold text-sm place-content-center items-center p-15 dark:text-white dark:bg-gray-500/30">
        <div>Designed &#38; built by Abdus Salam</div>
        <div className="text-gray-500 mt-1 dark:text-gray-300">{version}</div>
      </div>
    </>
  );
}
