export const name = "Abdus Salam Laskar";
export const title: string = "Orion";
export const email: string = "Sajad.laskar1@gmail.com";
export const version: string = "v1.1.0";

export const navLinks = [
  { id: 1, name: "Home", url: "#home" },
  { id: 2, name: "About", url: "#about" },
  { id: 3, name: "Skills", url: "#skills" },
  { id: 4, name: "Contact", url: "#contact" },
];

export const socialLinks = [
  { link: "https://github.com/Sajad-Lx", name: "github" },
  {
    link: "https://www.linkedin.com/in/abdus-salam-laskar",
    name: "linkedin",
  },
  { link: "https://www.instagram.com/sajad_lx", name: "instagram" },
];

export const skillLanguages = [
  { name: "Java" },
  { name: "C/C++" },
  { name: "Python" },
  { name: "JavaScript" },
  { name: "Typescript" },
];

export const skillDatabase = [
  { name: "MySQL" },
  { name: "SQLite" },
  { name: "MongoDB" },
];

export const skillOS = [{ name: "Windows" }, { name: "Linux - Ubuntu" }];

export const skillTechnologies = [
  { name: "React" },
  { name: "Next.js" },
  { name: "Django" },
  { name: "Android Studio" },
  { name: "Node.js" },
];
